<template>
  <vx-card class="receive-document main-box top-zero-radius md:w-1/2 p-2">
    <div class="sources">
      <div class="label">{{ $t('treasury.receive.document.labels.sources') }}</div>
      <ul>
        <li>
          <div class="row-number">1</div>
          <div class="name">{{ `${sources.name || ''} ${sources.family || ''}` }}</div>
          <div class="price" :class="{'text-danger': price < 0, 'text-success': price >= 0}">{{ addComma(Math.abs(price)) }} {{ $locale.currency() }}</div>
        </li>
      </ul>
    </div>

    <div class="destinations mt-4">
      <div class="label">{{ $t('treasury.receive.document.labels.destinations') }}</div>
      <ul>
        <li>
          <div class="row-number">1</div>
          <div class="name">{{ destinations.name || destinations.title }}</div>
          <div class="price" :class="{'text-danger': price < 0, 'text-success': price >= 0}">{{ addComma(price) }} {{ $locale.currency() }}</div>
        </li>
      </ul>
    </div>

    <div class="details mt-4">
      <div class="label">{{ $t('treasury.receive.document.labels.details') }}</div>
      <ul>
        <li v-for="(detail, detail_index) in details" :key="detail_index">
          <div class="row-number">{{ detail_index + 1 }}</div>
          <div class="key">{{ detail.key }}</div>
          <div class="value" :class="detail.class || ''">{{ detail.value }}</div>
        </li>
      </ul>
    </div>

    <custom-dialog ref="deleteDocumentConfirmation"
                   :title="`${$t('treasury.receive.document.confirmations.delete.title')}`"
                   :body="`${$t('treasury.receive.document.confirmations.delete.body')}`"
                   @accept="deleteDocument"/>

    <custom-dialog ref="confirmReceiveConfirmation"
                   :title="`${$t('treasury.receive.document.confirmations.confirm.title')}`"
                   :show-buttons="true"
                   access-color="success"
                   @accept="confirmReceiveDocument">
      <div class="confirm-receive">
        <custom-validate-input :label="`${$t('treasury.receive.labels.transactionsReferenceId')}`"
                               :classes="{'w-full': true}"
                               id="fastReceive"
                               :regex="$validator('regex.treasury.receive.transactionId')"
                               @pressEnter="confirmReceiveDocument"
                               v-model="transactionId"/>
      </div>
    </custom-dialog>

    <button v-show="false" id="DeleteDocumentBTN" @click="$refs.deleteDocumentConfirmation.showDialog()"></button>
    <button v-show="false" id="confirmReceiveBTN" @click="$refs.confirmReceiveConfirmation.showDialog()"></button>
  </vx-card>
</template>

<script>
  import {confirmReceive, deleteReceive, getReceive} from '../../../../../http/requests/treasury/receive'
import CustomDialog from '../../../../../components/customDialog/customDialog'
import {addComma} from '../../../../../assets/js/functions'
import CustomValidateInput from "../../../../../components/customInput/customValidateInput";
  import setDocumentDescription from "@/mixins/setDocumentDescription";

export default {
  name: 'receiveDocument',
  components: {CustomValidateInput, CustomDialog},
  metaInfo () {
    return {
      title: this.$route.params.id ? this.$t('treasury.receive.document.dynamicTitle', {
        docNumber: this.$route.params.id
      }) : this.$t('treasury.receive.document.title')
    }
  },
  mixins: [setDocumentDescription],
  data () {
    return {
      sourceTypesMapper: {
        'User': this.$t('treasury.receive.types.user'),
        'Bank': this.$t('treasury.receive.types.bank'),
        'Cash': this.$t('treasury.receive.types.cash'),
        'Storeroom': this.$t('treasury.receive.types.storeroom'),
        'PaymentGateway': this.$t('treasury.receive.types.paymentGateway')
      },
      transactionId: {
        value: '',
        isValid: false
      },
      destinations: [],
      sources: [],
      status: '',
      price: 0,
      details: [],
      actions: {
        toolbar: [],
        leftToolbar: [
          {
            id: 'DeleteDocumentBTN',
            permission: 'receive.delete',
            color: 'danger',
            icon: 'TRASH',
            iconPack: 'useral'
          },
          {
            id: {name: 'receiveActivitiesLog', params: {id: this.$route.params.id}},
            type: 'link',
            icon: 'LAST_ACTIVITIES',
            iconPack: 'useral',
            permission: 'activity_logs.show'
          },
          {
            id: {name: 'print-receive-page', params: {id: this.$route.params.id}},
            type: 'new-page-link',
            icon: 'PRINT',
            iconPack: 'useral'
          },
          {
            id: 'routeBackBTN',
            route: {name: 'treasuryReceive'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      this.$store.dispatch('setPageTitle', this.$route.params.id ? this.$t('treasury.receive.document.dynamicTitle', {
        docNumber: this.$route.params.id
      }) : this.$t('treasury.receive.document.title'))
    })

    this.getDocument()
  },
  methods: {
    getDocument () {
      getReceive(this.$route.params.id).then(response => {
        const document = response.data.data
        this.destinations = document.payee
        this.sources = document.payer
        this.price = document.price
        this.status = {
          name: document.status === 1 ? this.$t('treasury.receive.statusTypes.confirm') : this.$t('treasury.receive.statusTypes.cancel'),
          class: document.status === 1 ? 'text-success' : 'text-danger'
        }

        this.details = []

        this.details.push({
          key: this.$t('treasury.receive.document.labels.status'),
          value: document.status === 1 ? this.$t('treasury.receive.statusTypes.confirm') : this.$t('treasury.receive.statusTypes.cancel'),
          class: document.status === 1 ? 'text-success' : 'text-danger'
        })

        this.details.push({
          key: this.$t('treasury.receive.document.labels.type'),
          value: this.$t(`treasury.receive.actionTypes.${document.type}`)
        })
        this.details.push({
          key: this.$t('treasury.receive.document.labels.date'),
          value: document.date
        })
        this.details.push({
          key: this.$t('treasury.receive.document.labels.transactionId'),
          value: document.transaction_id || ''
        })
        this.details.push({
          key: this.$t('treasury.receive.document.labels.paymentConfirm'),
          value: document.confirmation_id || ''
        })
        this.details.push({
          key: this.$t('treasury.receive.document.labels.description'),
          value: document.description ? document.description : this.generateDocumentDescription({type: 'receive', reference: document}, {hasRoute: false})
        })


        const actions = JSON.parse(JSON.stringify(this.actions))
        if (document.status !== 1 && actions.toolbar.map(elm => elm.id).indexOf('confirmReceive') === -1) {
          actions.toolbar.push({
            id: 'confirmReceiveBTN',
            icon: 'CHECK',
            iconPack: 'useral',
            color: 'success',
            permission: 'receive.confirm'
          })
        }
        setTimeout(() => {
          this.$store.dispatch('updateNavbarActions', actions)
        }, 50)
      })
    },
    addComma (val) {
      return addComma(val)
    },
    deleteDocument () {
      deleteReceive(this.$route.params.id).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.receive.document.notifications.delete.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })
        this.$router.replace({name: 'treasuryReceive'})
      }).catch(() => {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.document.notifications.delete.error'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      })
    },
    confirmReceiveDocument () {
      if (!this.transactionId.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidTransactionsReferenceId'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      const payload = {
        transaction_reference_id: this.transactionId.value
      }
      confirmReceive(this.$route.params.id, payload).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.receive.document.notifications.confirm.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })
        this.getDocument()
      }).catch(() => {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.document.notifications.confirm.error'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.receive-document {

  .sources, .destinations, .details {

    .label {
      font-size: 14px;
      font-weight: 600;
      line-height: 50px;
      border-bottom: 1px solid #cecece;
    }

    ul {

      li {
        font-size: 13px;
        line-height: 35px;
        background: #cecece40;
        display: flex;
        flex-direction: row;

        .row-number {
          min-width: 50px;
          text-align: center;
        }

        .name {
          flex: 3;
        }

        .price {
          flex: 1;
          text-align: center;
        }

        .key {
          flex: 1;
          text-align: center;
        }

        .value {
          flex: 1;
          text-align: center;
          direction: rtl;
        }

        &:nth-child(2n) {
          background: #cecece10;
        }
      }
    }
  }
}
</style>
